import { createActionGroup, createReducer, createSelector, emptyProps, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { ITableName } from '@Shared/models/index.model';
import { IFieldNameKVP } from '@Reusables/reusable-pages/Report/report-extras/report.model';
import { ITableRelation } from '../../Services/table-relations.service';

type ITableGroup = string;
interface ITablesByGroup {
  group: string;
  tables: ITableName[];
}
interface IColumnsByTable {
  tableName: string;
  schema: string;
  fields: IFieldNameKVP[];
}

export namespace TablesStore {
  //#region STORE
  export interface IState {
    groups?: ITableGroup[];
    tables: EntityState<ITablesByGroup>;
    allTables: EntityState<ITableRelation>;
    columns: EntityState<IColumnsByTable>;
    gotAllTables?: boolean;
  }

  const tablesAdapter = createEntityAdapter<ITablesByGroup>({
    selectId: (i) => i.group,
  });
  const allTablesAdapter = createEntityAdapter<ITableRelation>({
    selectId: (i) => i.schema + i.primaryTable,
  });
  const columnsAdapter = createEntityAdapter<IColumnsByTable>({
    selectId: (i) => `${i.tableName}~/~${i.schema}`,
  });

  const initialState: IState = {
    // groups: ['1', '2', '3'],
    tables: tablesAdapter.getInitialState(),
    allTables: allTablesAdapter.getInitialState(),
    columns: columnsAdapter.getInitialState(),
  };
  //#endregion

  //#region ACTIONS
  export const actions = {
    groups: createActionGroup({
      source: 'Table Groups',
      events: {
        getAll: emptyProps(),
        getAllSuccess: props<{ result: ITableGroup[] }>(),
      },
    }),
    tables: createActionGroup({
      source: 'Tables',
      events: {
        getByGroup: props<{ group: string }>(),
        getByGroupSuccess: props<{ result: ITablesByGroup }>(),
      },
    }),
    allTables: createActionGroup({
      source: 'All Tables',
      events: {
        getAll: emptyProps(),
        getAllSuccess: props<{ result: ITableRelation[] }>(),
      },
    }),
    columns: createActionGroup({
      source: 'Columns',
      events: {
        getByTable: props<{ tableName: string; schema: string }>(),
        getByTableSuccess: props<{ result: IColumnsByTable }>(),
      },
    }),
  };
  //#endregion

  //#region SELECTORS
  export namespace selectors {
    const select = (state: IAppState) => state.tables;

    export const groups = createSelector(select, (state) => {
      // debugger;
      return state.groups;
    });

    const allTablesEntites = createSelector(select, (state) => state.allTables);
    export const allTables = createSelector(allTablesEntites, allTablesAdapter.getSelectors().selectAll);

    export const gotAllTables = createSelector(select, (state) => state.gotAllTables);
    const tablesByGroup = createSelector(select, (state) => state.tables);
    export const tablesByGroupMap = createSelector(
      tablesByGroup,
      tablesAdapter.getSelectors().selectEntities,
    );

    const columnsByTable_Schema = createSelector(select, (state) => state.columns);
    const columns = createSelector(columnsByTable_Schema, columnsAdapter.getSelectors().selectAll);
    export const columnsBySchemaMap = createSelector(columns, (columns) => {
      const schemaMap: { [schema: string]: { [tableName: string]: IFieldNameKVP[] } } = {};
      for (const item of columns)
        if (!schemaMap[item.schema]) schemaMap[item.schema] = { [item.tableName]: item.fields };
        else schemaMap[item.schema][item.tableName] = item.fields;
      return schemaMap;
    });
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(actions.groups.getAllSuccess, (state, { result }) => ({ ...state, groups: result })),
    on(actions.tables.getByGroupSuccess, (state, { result }) => ({
      ...state,
      tables: tablesAdapter.setOne(result, state.tables),
    })),
    on(actions.allTables.getAllSuccess, (state, { result }) => ({
      ...state,
      allTables: allTablesAdapter.setAll(result, state.allTables),
      gotAllTables: true,
    })),
    on(actions.columns.getByTableSuccess, (state, { result }) => ({
      ...state,
      columns: columnsAdapter.setOne(result, state.columns),
    })),
  );
  //#endregion
}
